import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Recheck/recheckSlice";
import { addToRecheckList, addToReissueList } from "../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetRecheckBasicDataQuery } from "../../../../../store/queries/Orders";
import { getAgentData } from "../../../Order/api";
import { useEffect, useState } from "react";
import * as Yup from "yup";

const useRecheckDetails = ({ refetch }) => {
  const dispatch = useDispatch();
  const recheckState = useSelector((state) => state.recheck);
  const { data: recheckBasicData } = useGetRecheckBasicDataQuery();

  const clearSelectedList = () => {
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
        state.showAddRecheckModal = false;
      })
    );
  };

  const [collectionAgentList, setCollectionAgentList] = useState([])

  const initialValues = {
    shop_id: "",
    delivery_person: "",
    delivery_person_number: "",
    delivery_person_address: "",
    due_date: "",
    due_time: "",
    remark: "",
  };
  const validation = Yup.object({
    shop_id: Yup.string().nullable(),
    delivery_person: Yup.string()
      .required("Enter Name"),
      // .trim()
      // .max(80, "Enter Valid Name"),
      // .matches(/^[a-zA-Z ]+$/, "Enter Valid Name"),
    delivery_person_number: Yup.string()
      // .required("Enter Phone number")
      .max(14, "Enter valid number")
      .min(6, "Enter valid number")
      .matches(/^[0-9]*$/, "Enter valid number"),
    delivery_person_address: Yup.string()
      .trim()
      // .required("Enter Address")
      .min(5, "Not a valid address"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values) => {
      // console.log("values", values)
      const params = {
        item_details_id: recheckState.selectedRecheck[0] ?? [],
        shop_id: values?.shop_id ?? "",
        delivery_person: values?.delivery_person ?? "",
        contact_number: values?.delivery_person_number ?? "",
        address: values?.delivery_person_address ?? "",
        due_date: values?.due_date ?? "",
        due_time: values?.due_time ?? "",
        remark: values?.remark ?? ""
      };
      let newObj = Object.entries(params);
      newObj = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      try {
        let response;
        if (recheckState?.selectedOption) {
          response = await addToRecheckList(newObj);
        } else {
          response = await addToReissueList(newObj);
        }
        if (response?.data?.status_code === 200) {
          toast.success(
            `Item added to ${
              recheckState?.selectedOption ? "recheck" : "reissue"
            } list successfully`
          );
          clearSelectedList();
          handleCancel();
          refetch();
        } else {
          toast.error("Something went wrong:");
          clearSelectedList();
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  // console.log("formik", formik.errors);
  useEffect(() => {
    if (
      recheckState?.showAddRecheckModal ||
      recheckState?.showAddReissueModal
    ) {
      if (
        formik?.values?.delivery_person_number?.length > 8 &&
        formik?.values?.delivery_person_number?.length < 15
      ) {
        getAgentData(formik?.values?.delivery_person_number).then(
          (response) => {
            if (response?.data?.status_code === 200) {
              if(response?.data?.data?.length !== 0) {
                formik?.setFieldValue(
                  "delivery_person_address",
                  response?.data?.data?.[0]?.delivery_details?.address
                );
                formik?.setFieldValue(
                  "delivery_person",
                  response?.data?.data?.[0]?.delivery_details?.person
                );
    
                let newObj = {
                  value: response?.data?.data?.[0]?._id,
                  label: `${response?.data?.data?.[0]?.delivery_details?.person} (${response?.data?.data?.[0]?.delivery_details?.contact_number})`,
                }
    
                formik?.setFieldValue(
                  "collection_agent",
                  newObj
                );
              }
              // formik?.setFieldValue(
              //   "delivery_person_address",
              //   response?.data?.data[0]?.delivery_details?.address
              // );
              // formik?.setFieldValue(
              //   "delivery_person_number",
              //   response?.data?.data[0]?.delivery_details?.contact_number
              // );
              // formik?.setFieldValue(
              //   "delivery_person",
              //   response?.data?.data[0]
              // );
            }
          }
        );
      }
    }
    //eslint-disable-next-line
  }, [formik?.values?.delivery_person_number]);
  const getFieldError = (fieldName) => {
    if (formik?.touched[fieldName] && formik?.errors[fieldName]) {
      return formik?.errors[fieldName];
    }
    return "";
  };

  const handleCancel = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddRecheckModal = false;
        state.showAddReissueModal = false;
      })
    );
  };
  const shopObj = recheckBasicData?.data?.shop_list?.filter(
    (item) => item?._id === formik?.values?.shop_id
  );

  const searchAgent = (value) => {
    formik.setFieldValue("collection_agent", value || "")

    const matchedObject = collectionAgentList.find(
      item => item._id === value?.value
  );
if(matchedObject) {
  formik.setFieldValue("delivery_person", matchedObject?.delivery_details?.person);
  formik.setFieldValue("delivery_person_address", matchedObject?.delivery_details?.address);
  formik.setFieldValue("delivery_person_number", matchedObject?.delivery_details?.contact_number);
}
else {
  formik.setFieldValue("delivery_person", value?.label);
}
      
  }

  const fetchCollectionAgents = (searchTerm) => {
    getAgentData(searchTerm).then((response) => {
      if (response?.data?.status_code === 200) {
        setCollectionAgentList(response?.data?.data)
      }
    });
    
  };

  const formattedCollectionAgentList = collectionAgentList?.map(agent => ({
    value: agent._id,
    label: `${agent?.delivery_details?.person} (${agent?.delivery_details?.contact_number})`,
}));

const setAddress = (e) => {
  formik.setFieldValue("delivery_person_address", e?.target?.value)
}

const setPhoneNo = (e) => {
  formik.setFieldValue("delivery_person_number", e?.target?.value)
}

  return {
    formik,
    shopObj,
    shopData: recheckBasicData?.data?.shop_list,
    getFieldError,
    handleCancel,
    searchAgent,
    fetchCollectionAgents,
    collectionAgentList,
    formattedCollectionAgentList,
    setAddress,
    setPhoneNo
  };
};

export default useRecheckDetails;

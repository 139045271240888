import useFontCases from "../../../../../utils/hooks/useFontCases";

export const useCardPrint = () => {
  
  const { getFontCase } = useFontCases();

  const renderText = (text, type) => {
        const fontCase = getFontCase(parseInt(type));
        switch (fontCase) {
          case "Uppercase": return <span style={{ textTransform: "uppercase" }}>{text}</span>;
          case "Lowercase": return <span style={{ textTransform: "lowercase" }}>{text}</span>;
          case "Title_Case": return <span style={{ textTransform: "capitalize" }}>{text}</span>;
          case "Sentence_Case":
              const sentenceCase = text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
              return <span dangerouslySetInnerHTML={{__html : sentenceCase}}/>;
          case "Camel_Case":
              const camelCase = text.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => index === 0 ? letter.toLowerCase() : letter.toUpperCase());
              return <span dangerouslySetInnerHTML={{__html : camelCase}}/>;
          default: return <span dangerouslySetInnerHTML={{__html: text}}/>;
        }
  };



  const checkSyleCode = (data) => { 
    let splitArray = data?.split(' ');
    let regex = /Style#-?\w+|\bStyle#\(\w+\)\w*/g;
    var result = '';

    splitArray?.forEach((element, _index) => {
        if (regex?.test(element)) {
            // Wrap the matched style code in a span tag
            result += `<span style="white-space: pre">${element}</span> `;
        } else {
            result += `${element} `;
        }
    });
    return result;
   };

  return{
    renderText,
    checkSyleCode
  }
}

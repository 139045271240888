// import QRCode from "react-qr-code";
// import Barcode from "react-barcode";
import { useCardPrint } from "./useCardPrint";
import JsBarcode from 'jsbarcode';
import { createCanvas } from 'canvas';
import QR from "qrcode-base64";
import QRCode from "react-qr-code";

export const StaticHtmlTemplateD = ({value}) => {

  const { renderText, checkSyleCode } = useCardPrint();

  const arrayItems = value?.data?.graded_values;
  const topArray = arrayItems?.slice(0,2);
  const middleArray = arrayItems?.slice(2);

  //to change the length of key
  const maxKeyLength = arrayItems?.reduce((max, str) => Math.max(max, str?.text?.length), 0);

  var qrData = QR.drawImg(
    value?.qr,
    {
      typeNumber: 5,
      errorCorrectLevel: "M",
      size: 37
    }
  );


  const generateBarcodeBase64 = (value) => {
    const canvas = createCanvas();
    JsBarcode(canvas, value, {
      format: "CODE128", // Barcode type
      width: 1,
      height: 15,
      margin : 0,
      displayValue: false // Set to true if you want to display the number below
    });
  
    // Convert the canvas to a base64 string
    return canvas.toDataURL("image/png");
  };
  
  const barcodeData = generateBarcodeBase64(value?.certificate_number);

  return (
    <>
      <div 
      style={{
        ...styles.outer_wrapper,
        }}>
          <img style={styles.bg_image} src={value?.background_image} alt="background images"/>
        <div style={styles.header_wrap}>
            <img style={styles.header_img} src={value?.front_top_image || ""} alt="Header"/>
            {parseInt(value?.qr_and_image_format) === 2 && value?.qr && (
              <span style={styles.qr_wrap}>
                <QRCode
                  value={value?.qr}
                  width={39.37}
                  height={39.37}
                  style={styles.qr_code}
                />
              </span>
            )}
            {/* {parseInt(value?.qr_and_image_format) === 2 && value?.qr && (
            <span style={styles.qr_wrap}>
              <img src={qrData} alt="qrcode" style={styles.qr_code}/>
            </span>
          )} */}
        </div>
        <div style={styles.body}>
          <div style={styles.top_wrap}>
            <div style={styles.row}>
              <p style={{...styles.top_key, width : maxKeyLength > 14 ? 78 : 73}}>
                {value?.bold === 1 ? (
                  <b style={{fontWeight : "600"}}>SUMMARY NO</b>
                ) : "SUMMARY NO"}
              </p>
              <p style={styles.top_separate}>:</p>
              <p style={styles.value}>
                {value?.bold === 1 ? (
                  <b style={{fontWeight : "600"}}>{value?.certificate_number}</b>
                ): value?.certificate_number}
              </p>
            </div>
            {topArray?.map((item,index)=>(
              <div style={styles.row} key={index}>
                <p style={{...styles.top_key, width : maxKeyLength > 14 ? 78 : 73}}>
                  {item?.bold === 1 ? (
                    <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(item?.text, item?.key_font_case)}</b>
                    ) : renderText(item?.text, item?.key_font_case)
                  }
                </p>
                <p style={styles.separate}>:</p>
                <p style={styles.top_value}>
                  {item?.bold === 1 ? (
                    <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(item?.value, item?.value_font_case)}</b>
                    ) : renderText(item?.value, item?.value_font_case)
                  }
                </p>
              </div>
            ))}
          </div>
          <div style={styles.middle_wrap}>
            <div style={styles.content_wrap}>
              {middleArray?.map((item,index)=>(
                <div style={styles.row} key={index}>
                  <p style={{...styles.key, width : maxKeyLength > 14 ? 78 : 73}}>
                    {item?.bold === 1 ? (
                      <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(item?.text, item?.key_font_case)}</b>
                      ) : renderText(item?.text, item?.key_font_case)
                    }
                  </p>
                  <p style={styles.separate}>:</p>
                  <p style={styles.value}>
                    {item?.bold === 1 ? (
                      <b style={{fontSize : item?.text?.length >= 12 ? 6.8 : 'inherit', fontWeight : "600"}}>{renderText(checkSyleCode(item?.value),item?.value_font_case)}</b>
                      ) : renderText(checkSyleCode(item?.value),item?.value_font_case)
                    }
                  </p>
                </div>
              ))}
            </div>
            <div style={styles.img_wrap}>
              <img style={styles.main_img} src={value?.image || ""} alt="Card images" />
              <img style={{...styles.main_img, marginTop :1}} src={value?.second_image || ""} alt="Card Second images" />
            </div>
          </div>
        </div>
        <div style={styles.footer_wrap}>
          {
            parseInt(value?.footer_type) !== 2 ? (
              <div style={styles.barcode_wrap}>
                {value?.certificate_number && (
                  // <Barcode
                  // value={value?.certificate_number}
                  // height={15}
                  // width={0.5}
                  // displayValue={false}
                  // margin={0}
                  // />
                  <img src={barcodeData} alt="barcode" style={styles.barcode} />
                )}
              </div>
              ) :(
              <img style={styles.footer_img} src={value?.front_foot_image || ""} alt="Card footer"/>
            )
          }
        </div>
      </div>
    </>
  );
};

const styles = {
  outer_wrapper:  {
    width  :321,
    minWidth : 321,
    maxWidth : 321,
    height : 207,
    maxHeight : 207,
    minHeight : 207,
    border  : '1px solid #f3f3f3',
    color : "#000000",
    overflow: "hidden",
    fontFamily: "Graphik",
    backgroundColor: "#ffffff",
    position: "relative",
  },
  header_wrap : {
    display : "flex",
    alignItems : "center",
    maxWidth : '100%',
    height : 47.24,
    // height : '1.2cm'
    width : '100%',
    position : 'relative',
  },
  header_img :{
    maxWidth : 321,
    width : '100%',
    maxHeight : 47.24,
    height : 47.24,
  },
  qr_wrap : {
    width : 39.37, 
    height : 39.37, 
    display : "inline-block", 
    position : "absolute",
    top : 2,
    right : 10,
  },
  qr_code : {
    width: 39.37,
    height: 39.37,
  },
  footer_wrap : {
    height : 15,
    // height : '0.8cm'
    width : '100%',
    display : "flex",
    position : 'relative',
  },
  footer_img :{
    width : '100%',
    maxHeight : 15,
    height : 15,
  },
  body: {
    height : 141.76,
    maxHeight : 141.76,
    padding : '1px 10px',
    position : 'relative',
  },
  middle_wrap: {
    display : 'flex',
    padding : '1px 0',
    // alignItems : "center",
  },
  row :{
    display : 'flex',
    padding : '0.9px 0px'
  },
  key: {
    width: 70,
    fontSize: 7.8,
    fontWeight: 400,
    lineHeight: "1.13",
    marginBottom:0,
  },
  value: {
    flex : "1",
    verticalAlign: "top",
    fontWeight: 400,
    lineHeight: "1.13",
    marginBottom:0,
    fontSize : 7.8,
  },
  top_key: {
    width: 70,
    fontSize: 7.8,
    fontWeight: 400,
    lineHeight: "1.06",
    marginBottom:0,
  },
  top_value: {
    flex : "1",
    verticalAlign: "top",
    fontWeight: 400,
    lineHeight: "1.06",
    marginBottom:0,
    fontSize : 7.8,
  },
  separate: {
    width: 10,
    paddingRight: 4,
    paddingLeft: 4,
    fontWeight : 400,
    fontSize:  7.8,
    lineHeight: "1.13",
    marginBottom:0,
  },
  top_separate: {
    width: 10,
    paddingRight: 4,
    paddingLeft: 4,
    fontWeight : 400,
    fontSize:  7.8,
    lineHeight: "1.06",
    marginBottom:0,
  },
  content_wrap :{
    flex : "1",
  },
  img_wrap : {
    width : 90,
    marginRight:4,
  },
  main_img :{
    width: '100%',
    maxWidth : 90,
    height : 55,
    maxHeight : 55,
  },
  barcode_wrap: {
    textAlign: "center",
    backgroundColor: "#ffffff",
    lineHeight: 0,
    width : "100%",
    display: "flex",
    alignItems : "center",
    justifyContent: "center",
  },
  bg_image: {
    position : "absolute",
    top : 0,
    left : 0,
    width : "100%",
    height : "100%",
  },
};
